<div class="row" style="background-color: black;">
    <div class="col-md-12">
        <h1 id="title">Henri Roque</h1>
    </div>
</div>

<div class="row">
    <div *ngIf="!mobile">
        <ul class="nav-bar">
            <nav>
                <li><a routerLink="sculptural">Sculptural</a></li>
            </nav>
            <nav>
                <li><a routerLink="functional">Functional</a></li>
            </nav>
            <nav>
                <li><a routerLink="paintings">Paintings</a></li>
            </nav>
            <nav>
                <li><a routerLink="music">Music</a></li>
            </nav>
            <nav>
                <li><a routerLink="bio">Bio</a></li>
            </nav>
            <nav>
                <li><a routerLink="contact">Contact</a></li>
            </nav>
        </ul>
    </div>

    <div *ngIf="mobile">
        <div class="dropdown" style="text-align: center;">
            <span class="glyphicon glyphicon-menu-hamburger" (click)="openNav()"></span>
            <div id="myDropdown" class="dropdown-content center fade-in">
                <a routerLink="sculptural" (click)="closeNav()">Sculptural</a>
                <a routerLink="functional" (click)="closeNav()">Functional</a>
                <a routerLink="paintings" (click)="closeNav()">Paintings</a>
                <a routerLink="music" (click)="closeNav()">Music</a>
                <a routerLink="bio" (click)="closeNav()">Bio</a>
                <a routerLink="contact" (click)="closeNav()">Contact Me</a>
            </div>
        </div>

    </div>

</div>

<div>

</div>