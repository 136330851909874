<h2 style="text-align: center;">Jeffery Toast</h2>
<h4 style="text-align: center;">I play guitar and sing in the band Jeffery Toast. Below are links to our music on
  various platforms and our albums embeded in the page. Feel free to
  listen and let me know what
  you think!</h4>
<div class="center" style="text-align: center; margin-bottom: 10px;">
  <a href="https://open.spotify.com/artist/2AQQxia5P8HOfZ1Cgca8PL?si=XhHxsbIxRgaDx_-5-F2WFA">Spotify </a>|
  <a href="https://jefferytoast.bandcamp.com/">bandcamp </a>|
  <a href="https://soundcloud.com/jefferytoast">Soundcloud </a>
</div>

<mat-spinner id="spinner" class="center"></mat-spinner>
<div class="grid">
  <iframe style="border: 0; width: 350px; height: 470px;" (load)="playerLoaded()"
    src="https://bandcamp.com/EmbeddedPlayer/album=3923543143/size=large/bgcol=333333/linkcol=9a64ff/tracklist=false/transparent=true/"
    seamless><a href="https://jefferytoast.bandcamp.com/album/hold-me-deer">Hold Me Deer by Jeffery Toast</a></iframe>
  <iframe style="border: 0; width: 350px; height: 470px;" (load)="playerLoaded()"
    src="https://bandcamp.com/EmbeddedPlayer/album=2682181836/size=large/bgcol=333333/linkcol=9a64ff/tracklist=false/transparent=true/"
    seamless><a href="https://jefferytoast.bandcamp.com/album/says-nothing-ep">Says Nothing EP by Jeffery
      Toast</a>
  </iframe>
  <iframe style="border: 0; width: 350px; height: 470px;" (load)="playerLoaded()"
    src="https://bandcamp.com/EmbeddedPlayer/album=3363835738/size=large/bgcol=333333/linkcol=9a64ff/tracklist=false/transparent=true/"
    seamless><a
      href="https://jefferytoast.bandcamp.com/album/liberation-through-hearing-during-the-intermediate-state">Liberation
      Through Hearing During The Intermediate State by Jeffery Toast</a>
  </iframe>
  <iframe style="border: 0; width: 350px; height: 470px;" (load)="playerLoaded()"
    src="https://bandcamp.com/EmbeddedPlayer/album=2411692482/size=large/bgcol=333333/linkcol=9a64ff/tracklist=false/transparent=true/"
    seamless><a href="https://jefferytoast.bandcamp.com/album/metamorphosis-of-a-bicentennial-monarch-ep">Metamorphosis
      of a bicentennial monarch EP by Jeffery Toast</a>
  </iframe>
</div>