<div class="row">
  <h2 style="text-align: center;">Sculptural Artwork</h2>
</div>

<div class="row">
  <h4 style="text-align: center;">Follow me on Instagram <a href="https://www.instagram.com/onreeeeeee/">@onreeeeeee</a>
    to see more of my work and stay up to date on my latest endeavors</h4>
</div>

<div class="grid">
  <div *ngFor="let imgUrl of imgUrls; let index = index">
    <div class="card">
      <mat-spinner id="{{ index }}" class="center"></mat-spinner>
      <img src="{{ imgUrl }}" style='display: none' onload="this.style.display = 'block'" (load)="imgLoaded(index)"
        class="fade-in shadow">
    </div>
  </div>
</div>